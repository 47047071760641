import http, { setEnv, setLang } from './http';

export function setConfig({ env, lang }) {
  if (env) {
    setEnv(env);
  }
  if (lang) {
    setLang(lang);
  }
}

export async function getTicketData({ orderNumber }) {
  const response = await http.post('/ticket-data', {
    o: orderNumber,
  });

  return response;
}

export async function getQrData({ orderNumber, attendeePedulilindungiIds, qr }) {
  const response = await http.post('/ticket-data/peduli-lindungi/qr', {
    o: orderNumber,
    a: attendeePedulilindungiIds,
    qr,
  });

  return response;
}

export async function checkinQr({
  orderNumber,
  attendeePedulilindungiIds,
  qr,
  latitude,
  longitude,
  group,
}) {
  const payload = {
    o: orderNumber,
    a: attendeePedulilindungiIds,
    qr,
    lat: latitude,
    lon: longitude,
  };
  if (group) {
    payload.group = group;
  }
  const response = await http.post('/ticket-data/peduli-lindungi/scan', payload);

  return response;
}
