<template>
  <div class="goers-ticket min-h-screen">
    <div
      class="
        mx-auto w-full
        overflow-x-hidden
        sm:w-6/12 md:w-5/12
        lg:w-4/12
        xl:w-3/12
        bg-white
        min-h-screen
        relative
        z-40
      "
    >
      <router-view v-slot="{Component}">
        <component :is="Component" />
      </router-view>
    </div>
    <div id="modals" class="relative z-50 px-5" />
  </div>
</template>

<script setup>
import { useLangProvider } from './helper/translate';

useLangProvider();
</script>

<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Karla:wght@400;700&display=swap');
.goers-ticket{
  font-family: karla, sans-serif;
  background: #ffffff;
}

</style>
