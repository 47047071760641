export const RouteName = {
  TICKETS: 'TICKETS',
  TICKET_GROUP: 'TICKET_GROUP',
  TICKET_DETAIL: 'TICKET_DETAIL',
  CHECKIN: 'CHECKIN',
  CHECKIN_SCAN: 'CHECKIN_SCAN',
  CHECKIN_CONFIRM: 'CHECKIN_CONFIRM',
  CHECKIN_RESULT: 'CHECKIN_RESULT',
  CHECKIN_RESULT_DETAIL: 'CHECKIN_RESULT_DETAIL',
  CHECKIN_RESULT_QR_DETAIL: 'CHECKIN_RESULT_QR_DETAIL',
};
