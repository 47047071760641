import { createApp } from 'vue';
import { createPinia } from 'pinia';
import * as firebase from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import * as Sentry from '@sentry/vue';
import { Integrations } from '@sentry/tracing';
import SwiperCore, { Navigation, Virtual } from 'swiper';
import { APP_ENV, firebaseConfig, sentryConfig } from './config';
import App from './App.vue';
import router from './router';
import './index.css';
import 'swiper/swiper.scss';

firebase.initializeApp(firebaseConfig);
getAnalytics();
SwiperCore.use([Navigation, Virtual]);

const app = createApp(App)
  .use(createPinia())
  .use(router);

Sentry.init({
  app,
  environment: APP_ENV === 'development' ? 'development' : 'production',
  dsn: sentryConfig.dsn,
  integrations: [
    new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: /[0-9a-z]*\.goersapp\.com/,
    }),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.1,
});

app.mount('#app');
