import axios from 'axios';

const ApiBaseUrl = {
  PRODUCTION: 'https://payment.goersapp.com',
  DEVELOPMENT: 'https://devapi.goersapp.com',
};

const http = axios.create({
  baseURL: ApiBaseUrl.PRODUCTION,
  headers: {
    'Content-Type': 'application/json',
  },
});

export default http;

export function setEnv(env) {
  if (env === 'dev') {
    http.defaults.baseURL = ApiBaseUrl.DEVELOPMENT;
  }
}

export function setLang(lang) {
  http.defaults.headers.common['Accept-Language'] = lang;
}
