import {
  computed, inject, provide, ref,
} from 'vue';

export const translate = (lang) => {
  const id = {
    language: 'Bahasa',
    orderNumber: 'Nomor Order',
    title: 'Tiket Anda',
    attendee: 'Peserta',
    needHelp: 'Butuh Bantuan?',
    contactUsBy: 'Hubungi kami melalui :',
    chatWhatsApp: 'Chat WhatsApp',
    eMail: 'Email',
    buttonBack: 'Kembali',
    continue: 'Lanjut',
    ticket: 'Tiket',
    ticketReadyToUse: 'Tiket Siap Digunakan',
    filterTicket: 'Tiket Filter',
    filterTicketStatus: 'Filter Status Tiket',
    allTicket: 'Semua Tiket ',
    allTicketStatus: 'Semua Status Tiket ',
    readyToUse: 'Siap Digunakan',
    continueTo: 'Lanjut ke',
    descriptionContinueTo: 'Anda telah mencapai tiket terakhir pada daftar ini. Kllik tombol ‘Lanjut’ untuk melihat tiket pada kategori selanjutnya.',
    seeTicket: 'Lihat Tiket',
    from: 'dari',
    joinWithLink: 'Gabung dengan tautan',
    joinEvent: 'Gabung Acara',
    ticketNumber: 'Nomor Tiket',
    printTicket: 'Cetak Tiket',
    ticketType: 'Jenis Tiket',
    eventName: 'Nama Acara',
    venueName: 'Nama Tempat',
    addReminder: 'Tambahkan Pengingat',
    ticketSchedule: 'Jadwal Tiket',
    location: 'Lokasi',
    buyerInformation: 'Informasi Pembeli',
    attendeeInformation: 'Informasi Peserta',
    howToUseTicket: 'Cara Menggunakan Tiket',
    ticketInclude: 'Tiket Termasuk',
    ticketExclude: 'Tiket Tidak Termasuk',
    rules: 'Peraturan',
    termsAndConditions: 'Syarat dan Ketentuan',
    poweredBy: 'Dipersembahkan oleh',
    pageNotFound: 'Halaman tidak Ditemukan',
    pageNotFoundDescription: 'Kami tidak bisa menemukan halaman yang Anda cari. Tolong periksa kembali link yang anda gunakan.',

    peduliLindungiAttendeeInfo: 'Daftar dibawah diperoleh dari informasi peserta pada tiket Anda.',
    selectAll: 'Pilih Semua',
    fullName: 'Nama Lengkap',
    idNumber: 'Nomor Induk Kependudukan (NIK)',
    peduliLindungiTerm: 'Dengan mengklik ini saya menyetujui <a href="https://www.pedulilindungi.id/syarat-ketentuan" class="text-blue-green-400">Syarat Penggunaan</a> dan <a href="https://www.pedulilindungi.id/kebijakan-privasi-data" class="text-blue-green-400">Kebijakan Privasi</a> PeduliLindungi',
    verificationNote: 'Segala proses verifikasi termasuk penyimpanan data dan informasi akan dilakukan sepenuhnya oleh dan menjadi tanggung jawab PeduliLindungi.',
    continueScanQr: 'Lanjut Scan QR',
    checkInConfirmation: 'Konfirmasi Check-in',
    activityCategory: 'Kategori Aktivitas',
    totalCapacity: 'Total Kapasitas',
    checkInInformation: 'Informasi Check-in',
    checkInLocation: 'Lokasi Check-in',
    scanDateAndTime: 'Tanggal & Waktu Scan',
    viewQrCode: 'Lihat QR Code',
    statusColorDescription: ' Baca informasi lengkap mengenai status warna Anda <a href="https://faq.kemkes.go.id/faq/arti-status-warna-kode-qr-pedulilindungi" class="text-blue-green-400">di sini</a>',
    yourCheckin: 'Check-in Anda :',
    checkin: 'Masuk :',
    person: 'Orang',
    retryVerification: 'Verifikasi Ulang',
    usageTooltip: 'Jumlah berapa kali tiket dapat digunakan',
    seat: 'Kursi',
    seats: 'Kursi',
  };

  const eng = {
    language: 'Language',
    orderNumber: 'Order Number',
    title: 'Your Ticket',
    attendee: 'Attendee',
    needHelp: 'Need Help?',
    contactUsBy: 'Contact Us By :',
    chatWhatsApp: 'Chat WhatsApp',
    eMail: 'E-mail',
    buttonBack: 'Back',
    continue: 'Continue',
    ticket: 'Ticket',
    ticketReadyToUse: 'Tickets Ready to Use',
    filterTicket: 'Filter Ticket',
    filterTicketStatus: 'Filter Ticket Status',
    allTicket: 'All Tickets',
    allTicketStatus: 'All Tickets Status',
    readyToUse: 'Ready to Use',
    continueTo: 'Continue to',
    descriptionContinueTo: 'You have reached the last ticket on this list. Click \'Continue\' button to view tickets in the next category.',
    seeTicket: 'View Ticket',
    from: 'from',
    joinWithLink: 'Join with Link',
    joinEvent: 'Join Event',
    ticketNumber: 'Ticket Number',
    printTicket: 'Print Ticket',
    ticketType: 'Ticket Type',
    eventName: 'Event Name',
    venueName: 'Venue Name',
    addReminder: 'Add Reminder',
    ticketSchedule: 'Ticket Schedule',
    location: 'Location',
    buyerInformation: 'Buyer Information',
    attendeeInformation: 'Attendee Information',
    howToUseTicket: 'How to Use Ticket',
    ticketInclude: 'Ticket Include',
    ticketExclude: 'Ticket Exclude',
    rules: 'Rules',
    termsAndConditions: 'Terms and Conditions',
    poweredBy: 'Powered by',
    pageNotFound: 'Page not found',
    pageNotFoundDescription: 'We can\'t find the page you are looking for. Please recheck the link you have entered.',

    peduliLindungiAttendeeInfo: 'The list is generated from the attendee information from your tickets.',
    selectAll: 'Select All',
    fullName: 'Full Name',
    idNumber: 'ID Number',
    peduliLindungiTerm: 'I agree to <a href="https://www.pedulilindungi.id/syarat-ketentuan" class="text-blue-green-400">Terms of Use</a> and <a href="https://www.pedulilindungi.id/kebijakan-privasi-data" class="text-blue-green-400">Privacy Policy of</a> PeduliLindungi',
    verificationNote: 'All verification processes including data and information storage will be carried out entirely by and be the responsibility of PeduliLindungi.',
    continueScanQr: 'Continue to Scan QR',
    checkInConfirmation: 'Check-in Confirmation',
    activityCategory: 'Activity Category',
    totalCapacity: 'Total Capacity',
    checkInInformation: 'Check-in Information',
    checkInLocation: 'Check-in Location',
    scanDateAndTime: 'Scan Date & Time',
    viewQrCode: 'View QR Code',
    statusColorDescription: 'Read full information about your color status <a href="https://faq.kemkes.go.id/faq/arti-status-warna-kode-qr-pedulilindungi" class="text-blue-green-400">here</a>',
    yourCheckin: 'Your Check-in :',
    checkin: 'Check-in :',
    person: 'Person',
    retryVerification: 'Retry Verification',
    usageTooltip: 'How many times left for the ticket to be used',
    seat: 'Seat',
    seats: 'Seats',
  };

  if (lang === 'en') {
    return eng;
  }
  return id;
};

const Language = {
  EN: 'en',
  ID: 'id',
};

const INJECTION_LANG = Symbol('INJECTION_LANG');
const INJECTION_UPDATE_LANG = Symbol('INJECTION_UPDATE_LANG');
const INJECTION_TRANSLATION = Symbol('INJECTION_TRANSLATION');

export function useLangProvider() {
  const lang = ref(localStorage.getItem('lang') || Language.ID);
  function updateLang(lng) {
    localStorage.setItem('lang', lng);
    lang.value = lng;
  }

  const translation = computed(() => translate(lang.value));

  provide(INJECTION_LANG, lang);
  provide(INJECTION_UPDATE_LANG, updateLang);
  provide(INJECTION_TRANSLATION, translation);
}

export function useLang() {
  const lang = inject(INJECTION_LANG, Language.ID);
  const updateLang = inject(INJECTION_UPDATE_LANG, () => {});
  const translation = inject(INJECTION_TRANSLATION);

  return {
    lang,
    updateLang,
    translation,
  };
}
